'use client'
import { useState } from 'react';
import ReactDOM from "react-dom";
import TextTruncate from 'react-text-truncate';
import "./Home.css"
import { Link } from 'react-router';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

import Banner from './Banner';
import Banner2 from './Banner2';
import vision from '../../images/our vision.png'
import stripe from '../../images/paypal-strip.jpg'
import paypal from '../../images/paypal-visa-removebg-preview.png'
import digital from '../../images/digital-marketing.webp'
import ServiceShow from './ServicesShow';

const Home = () => {
const [countDownEnter, setCountDown]= useState(false)
    return (
        <div>
           <div className='box-border m-0 p-0 '>
            {/* Top */}
            <section className='h-full  py-16 lg:py-24 bg-img-top flex flex-col justify-center items-center  text-slate-50'>
              <div className='grid lg:grid-cols-2'>
             <div>
             <p className='mx-10 my-6 text-left text-4xl md:text-6xl sm:text-3xl lg:text-6xl'><span className='text-white'>Back Office Supporter,</span><span className=' text-img-top'> Your Ultimate Shadow Partner </span>​</p>
                {/* <p className='mx-10 mb-6 text-left'>
            NazSolution, a premier IT company, excels in customer service backed office, offering exceptional creative services for digital marketing, websites,video editing, social media and comprehensive digital solutions, backed by years of experience and skilled professionals.
            </p> */}
<p className='mx-10 mb-6 text-xs md:text-base lg:text-lg text-left'>
  
<TextTruncate
          
    line={7}
    element="span"
    truncateText="…"
    text=" NazSolution, a premier IT company, excels in customer service backed office, offering exceptional creative services for digital marketing, websites,video editing, social media and comprehensive digital solutions, backed by years of experience and skilled professionals."
   
/>
</p>
            <button className='border border-white px-1 py-5 rounded-md  bg-red-950/30 '><Link to="contact-us" className='border border-white p-4 bg-gradient-to-r from-sky-700 to-sky-400 hover:from-sky-400 hover:to-slate-700  hover:transition-colors transition-all rounded-md '>Get Connected</Link></button>
             </div>

            <div className='lg:m-8 flex flex-col justify-center items-center mx-2 my-10 md:mx-10 opacity-75 transition duration-300  hover:opacity-100'>
                <img className=' border-white border-8  rounded-lg ' src={digital} alt="w-auto" />
            </div>
              </div>
             


            </section>
            <section className=' h-full lg:py-20 py-6 md:py-16 bg-img-top1 flex flex-col justify-center items-center  text-slate-50'>
                <h1 className='border m-10 border-white px-2 py-5 rounded-md  bg-sky-950/30 hover:bg-sky-900 bg-gradient-to-r hover:from-sky-300 hover:to-sky-700 hover:transition-all'>Business Established</h1>
            <p className='mb-10 mx-10 text-left text-base md:text-lg lg:text-3xl text-gray-200 font-sans'>Established in 2021, Naz Solution is  a back office supporter. Providing steadfast employment for over a decade. Specializing in Sales and Support in IT Enabled Services (ITES), Renowned for reliability and innovation, the company fosters a positive work environment where employees thrive professionally. Noteworthy for empowering youth employment in US and grow the US division with its goals. significantly contributes to the growth of its US affiliate, embodying a culture of professionalism, innovation, and support.</p>

           


            {/* <ScrollTrigger onEnter={()=>setCountDown(true)} onExit={()=>setCountDown(false)}> */}
{/* autocount start  */}
<div className=' lg:flex-row flex flex-col gap-20 m-10 justify-center  items-center'>
<div>
    {/* expertise  */}
<p className='flex text-6xl'>
            {/* {countDownEnter &&  */}
            <CountUp start={0} end={100400} duration={2} delay={0}>
  {({ countUpRef }) => (
    <div>
      <span ref={countUpRef} ></span>
    </div>
  )}
</CountUp>
{/* }  */}
<span>+</span>
            </p>
            <p>Hours of Expertise</p>
</div>

{/* projects completed */}
<div>
<p className='flex text-6xl'>
            <CountUp start={0} end={250} duration={2} delay={0}>
  {({ countUpRef }) => (
    <div>
      <span ref={countUpRef} ></span>
    </div>
  )}
</CountUp> <span>+</span>
            </p>
            <p>Projects Completed</p>
</div>

{/* projects success  */}
<div>
<p className='flex text-6xl'>
            <CountUp start={0} end={90} duration={2} delay={0}>
  {({ countUpRef }) => (
    <div>
      <span ref={countUpRef} ></span>
    </div>
  )}
</CountUp> <span>%</span><span>+</span>
            </p>
            <p>Projects Success</p>
</div>

{/* agency partner */}
<div>
<p className='flex text-6xl'>
            <CountUp start={0} end={8}  duration={2} delay={0}>
  {({ countUpRef }) => (
    <div>
      <span ref={countUpRef} ></span>
    </div>
  )}
</CountUp> <span>+</span>
            </p>
            <p>Agency Partner</p>
</div>
</div>
{/* </ScrollTrigger>     */}



            </section>
            <section className='lg:py-20 py-6 md:py-16 h-full bg-img-top flex flex-col justify-center text-slate-50'>
           {/* <div className='flex justify-center'> <h1 className='border m-10 border-white px-2 py-5 rounded-md  bg-sky-950/30 hover:bg-sky-900 bg-gradient-to-r hover:from-sky-300 hover:to-sky-700 hover:transition-all'>Projects</h1></div> */}
           <div>
           <h1 className='m-10 text-3xl md:text-4xl lg:text-6xl'>Some of Our Services</h1>
           </div>
       {/* <div className='hidden sm:block md:block lg:block'><Banner></Banner></div> */}

       {/* <div className='block md:hidden sm:hidden lg:hidden px-6 '>
        <Banner2></Banner2>
       </div> */}
       <div>
       <ServiceShow></ServiceShow>

       </div>


            </section>
            <section className=' h-full lg:py-20 py-6 md:py-16  bg-img-top1 flex flex-col justify-center items-center  text-slate-50'>
            <h1 className='border m-10 border-white px-2 py-5 rounded-md  bg-sky-950/30 hover:bg-sky-900 bg-gradient-to-r hover:from-sky-300 hover:to-sky-700 hover:transition-all'>Our Vission</h1>
            <div className='my-10 '>
                <img src={vision} alt="w-auto" />
            </div>
            <p className='mb-10 mx-10 text-left text-base md:text-lg lg:text-3xl text-gray-200 font-sans'>Our vision is centered on enhancing employee experiences and cultivating a skilled youth workforce. Committed to providing meaningful employment for the youth, we focus on contributing to the local economy of the US LLC/ Corporation in its purpose or target to reach the milestone set forth for the future. . Our objective is clear: to be the go-to solution for job seekers by offering US work experience and a diverse skilled workforce. We aspire to bridge the gap between talented individuals and rewarding workplaces, ensuring that we are the premier destination for those seeking fulfilling employment opportunities.</p>


            </section>
            <section className=' h-full lg:py-20 py-6 md:py-16 bg-img-top flex flex-col justify-center items-center  text-slate-50'>
            <h1 className='border m-10 border-white px-2 py-5 rounded-md  bg-sky-950/30 hover:bg-sky-900 bg-gradient-to-r hover:from-sky-300 hover:to-sky-700 hover:transition-all'>Connect With Us</h1>
            
            <div className=''>
            <form class="w-full max-w-lg p-10">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        First Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-white rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane"/>
      {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Last Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe"/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Email
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="janedoe@email.com"/>
      {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
    </div>
  </div>
  {/* <div class="flex flex-wrap -mx-3 mb-2">
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
        City
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Albuquerque"/>
    </div>
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        State
      </label>
      <div class="relative">
        <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option>New Mexico</option>
          <option>Missouri</option>
          <option>Texas</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
        Zip
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="90210"/>
    </div>
  </div> */}

<Link to="/contact-us" class="block  items-center px-3 py-2 text-sm font-medium text-center text-white bg-sky-800 rounded-lg hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-blue-800">
            Submit
             {/* <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg> */}
        </Link>
</form>
            </div>
            <div className='mb-10'>
                
                <img src={paypal} alt="w-auto" />
            </div>

            </section>
           </div>
        </div>
    );
};

export default Home;