import React from 'react';
import './Refund.css'

const Privacy = () => {
    return (
        <div>
            <p className="text-sky-600 md:mt-20 mt-10 text-5xl">| Privacy Policy </p>

<div class="w-full h-full px-4 md:px-8 lg:px-12 py-12 md:py-16 lg:py-24 ">
  <div class="mx-auto shadow-lg rounded-lg border border-slate-800 m-4 overflow-hidden">

    {/* Tab 1  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab1" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab1" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">What is Privacy Policy?</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️Personal information is any information that relates to an identified or identifiable individual or company. We only collect personal information when it is necessary for conducting our business, and we only use lawful means to collect it. We inform clients why we are collecting their personal information either before or at the time we collect it. We will not use the information for any additional purposes without our clients’ consent. When appropriate, we collect personal information directly from clients and, again, when appropriate, we either notify them or obtain consent before collecting personal information from other sources.
    
        </li>
       </ol>
      </div>
    </div>


     {/* Tab 2  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab2" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab2" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Collection of Personal Information

      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️ Naz Solutions’s Guiding Principles state that we will maintain the highest standards of integrity in everything we do. One way we live up to this commitment is by ensuring the privacy and confidentiality of the personal information we receive from clients, staff, providers, and the community at large. We recognize and respect every individual’s right to privacy. We also recognize our obligation to preserve the confidentiality of personal information. For this reason, we have adopted these Privacy Guidelines for the collection, use, and disclosure of personal information.

       
        </li>

       </ol>
      </div>
    </div>

{/* tab 3  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab3" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab3" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Use and Disclosure of Personal Information
      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️ We do not disclose personal information to any third parties — nor use the information for any purpose other than for that which we collected it — without the clients’ consent, unless such disclosure or use is required by law or in the discharge of a public duty.


       
        </li>

       </ol>
      </div>
    </div>

    {/* Tab 4  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab4" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab4" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Access to Personal Information

      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
      
       <ol>
        <li class="text-lg  ">⚫️ We limit access to personal information to the employees and agents of Naz Solution who need the information for use or disclosure in accordance with these Privacy Guidelines. If you want to request personal information that we have retained in our records, write to us and identify yourself appropriately. We will respond within 60 days and advise you of any personal information we have retained in our records, subject to legal and ethical considerations. If we decide not to disclose the personal information to you, we will provide written reasons for our decision. If you disagree with our decision, you may appeal in writing to our Personal Information Officer.

       
        </li>


       </ol>
      </div>
    </div>


 {/* Tab 5  */}
 <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab5" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab5" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Accuracy of Personal Information


      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
      
       <ol>
        <li class="text-lg  ">⚫️ We make every reasonable effort to ensure that all the personal information we collect is as accurate and complete as possible. If we find that the personal information we have retained is incorrect, we will adjust it or seek help to have it corrected within 60 days. You may request, in writing, that we clarify or correct the personal information we have on file for you. We will respond to your request within 60 days. If we refuse your request, we will provide you with our reasons in writing. If you disagree, you may appeal in writing to our Personal Information Officer.



       
        </li>


       </ol>
      </div>
    </div>


     {/* Tab 6  */}
     <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab6" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab6" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Security of Personal Information Compliance with Our Privacy Guidelines


      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
      
       <ol>
        <li class="text-lg  ">⚫️ We have established, and will continue to maintain, appropriate safeguards to protect the security and confidentiality of personal information. We provide a copy of Naz Solution’s Privacy Guidelines to our employees, agents, clients, providers, and other persons or organizations who receive personal information and act for, or on behalf of, On Call Centre. They are required to comply with our Privacy Guidelines.



       
        </li>


       </ol>
      </div>
    </div>



  </div>
</div>

        </div>
    );
};

export default Privacy;