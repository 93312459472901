import React from 'react';
import customer from "../../images/customer-relationship.avif"
import video1 from "../../images/video-editor.avif"
import video2 from "../../images/video editor-2.jpeg"
import social1 from "../../images/social media-2.jpg"
import social2 from "../../images/social media.jpeg"
import { Link } from 'react-router';
import TextTruncate from 'react-text-truncate';
const ServiceShow = () => {
    return (
        <div>
            <div className='w-full h-full p-8 py-8'>

                <div className='   grid md:grid-cols-3 lg:grid-cols-3 gap-8'>
                <div class="max-w-md transition duration-300 hover:text-white hover:bg-yellow-200  bg-white border grid border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className='  overflow-hidden bg-white'>
   <Link to="#">
        <img class="rounded-t-lg scale-100 hover:scale-125 transition duration-200 " src={customer} alt="" />
    </Link>
    </div>
    <div class="p-5 ">
       
    <h5 class="mb-2 text-xl font-bold  tracking-tight text-gray-900 dark:text-white">Customer Relationships Officer</h5>
      
            <p class="mb-3 font-normal text-xs text-gray-700  dark:text-gray-400">
            
            <TextTruncate
                 
                 line={5    }
                 element="span"
                 truncateText="…"
                 text=" The work as a customer relationship officer is to communicate with the customer to understand the queries of them and place the order as per their need. Also its very important to behave well with the customer and create a good bond with them, So that they can feel good and relief to place their every order by contacting us. We provide 24/7 customer support for customers satisfaction."
                
             />

</p>

<div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-base text-sky-600 p-2'>Start From $30</p>
        <Link to="/services/service-price" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            View
             <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </Link>
</div>
    </div>
</div>
{/* tab-2 */}
<div class="max-w-md transition duration-300 hover:text-white hover:bg-yellow-200 bg-white border border-gray-200 grid  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
<div className='  overflow-hidden bg-white'>
   <Link to="/services/service-price">
        <img class="rounded-t-lg scale-125 hover:scale-150 transition duration-200 " src={social1} alt="" />
    </Link>
    </div>
    <div class="p-5 ">
       
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Social Media Management
            </h5>
      
        <p class="mb-3 font-normal text-xs text-gray-700 text-justify dark:text-gray-400">
       <TextTruncate
                 
           line={5}
           element="span"
           truncateText="…"
           text=" Build a loyal following with our affordable social media management! We create engaging content and manage your channels, driving targeted traffic that increases brand awareness and opens new revenue opportunities. Manage now!"
          
       />
      


</p>

<div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-base text-sky-600 p-2'>Start From $99</p>
        <Link to="/services/service-price" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            View
             <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </Link>
</div>
    </div>
</div>
{/* tab-3 */}
<div class="max-w-md transition duration-300 hover:text-white hover:bg-yellow-200 bg-white border border-gray-200 grid rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
<div className='  overflow-hidden bg-white'>
   <Link to="#">
        <img class="rounded-t-lg scale-100 hover:scale-125 transition duration-200 " src={social2} alt="" />
    </Link>
    </div>
    <div class="p-5 ">
       
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Video Editing Support</h5>
      
        <p class="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400">
        <TextTruncate
                 
                 line={5}
                 element="span"
                 truncateText="…"
                 text=" We have professional video editor who is responsible for taking the raw footage, then turning it into one cohesive video or film. The Video Editor followed an outline and script and any special instructions given about what should happen in each scene. We also make sure the video contains detailed information so the audience can relate easily.
"
                
             />


</p>

<div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-base text-sky-600 p-2'>Start From $30</p>
        <Link to="/services/service-price" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            View
             <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </Link>
</div>
    </div>
</div>


                    
                </div>
            </div>
        </div>
    );
};

export default ServiceShow;