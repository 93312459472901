import logo from './logo.svg';
import './App.css';
import Header from './Components/Shared/Header/Header';
import Footer from './Components/Shared/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About/About';
import Contact from './Components/Pages/Contact/Contact';
import Notfound from './Components/Pages/NotFound/Notfound';
import Refund from './Components/Pages/PolicyPages/Refund';
import Privacy from './Components/Pages/PolicyPages/Privacy';
import TermCondition from './Components/Pages/PolicyPages/TermCondition';
import Service from './Components/Pages/Services/Service';
import ServicePricing from './Components/Pages/ServicePricing/ServicePricing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
     <Header/>
     <Routes>
            <Route element={<Home />} exact path="/" />
            <Route element={<Home />} path="/home" />
            <Route element={<About />} path="/about-us" />
            <Route element={<Contact />} path="/contact-us" />
            <Route element={<Notfound />} path="*" />
            <Route element={<Service />} path="services" />
            <Route element={<ServicePricing />} path="services/service-price" />

            {/* Policy Pages  */}
            <Route element={<Refund />} path="refund-policy" />
            <Route element={<Privacy />} path="privacy-policy" />
            <Route element={<TermCondition />} path="term-conditions" />

      </Routes>
     <Footer/>
     </BrowserRouter>
    </div>
  );
}

export default App;
