import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import vision from '../../images/our-vision-new-1.jpg'
// import tour1 from '../../../images/optimize/aboutUs.jpg'
// import tour2 from '../../../images/optimize/aboutUs-2.jpg'
import "./About.css"
import ScrollAnimation from 'react-animate-on-scroll';
const About = () => {
    const checkFont=<FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
    return (
             <div className="bg-about text-gray-200">
           
        <div className="py-8 bg-blue-900 bg-opacity-10">
            <div  className="lg:px-20 lg:my-8">
            <div  className="lg:px-20 lg:my-8"> 
            <p className="text-sky-600 md:mt-20 mt-10 text-5xl">| About us</p>
            </div>

            <div className=" grid lg:grid-cols-2 grid-cols-1">
            {/* <ScrollAnimation className='order-2 lg:order-1' animateIn='bounceInRight'
  animateOut='bounceOutLeft'> */}
                    <div className="relative px-8  sm:mb-8">
                    <small className="text-pink-600 md:mt-20 mt-10 text-3xl">| Our Vision</small>
                  
                        <img src={vision} className="w-full rounded-md my-10" alt="" />
                       
                        <h3 className="text-base text-justify text-gray-300 my-4">Our vision is centered on enhancing employee experiences and cultivating a skilled youth workforce. Committed to providing meaningful employment for the youth, we focus on contributing to the local economy of the US LLC/ Corporation in its purpose or target to reach the milestone set forth for the future. . Our objective is clear: to be the go-to solution for job seekers by offering US work experience and a diverse skilled workforce. We aspire to bridge the gap between talented individuals and rewarding workplaces, ensuring that we are the premier destination for those seeking fulfilling employment opportunities.</h3>
                    </div>
                    {/* </ScrollAnimation> */}
                    <div className="px-8 lg:order-2 order-1 mt-12  lg:mt-0">
                        <small className="text-pink-600 md:mt-20 text-3xl">| Naz Solution</small>
                        <h2 className="text-2xl text-slate-200"> We are Trusted Name in Back Office Support Services</h2>
                        <h3 className="text-base text-justify text-gray-300 my-4">Established in 2021, Naz Solution is  a back office supporter. Providing steadfast employment for over a decade. Specializing in Sales and Support in IT Enabled Services (ITES), Renowned for reliability and innovation, the company fosters a positive work environment where employees thrive professionally. Noteworthy for empowering youth employment in US and grow the US division with its goals. significantly contributes to the growth of its US affiliate, embodying a culture of professionalism, innovation, and support.</h3>
                        <small className="text-pink-600 md:mt-20 mt-10 text-3xl">| WHAT WE WANT
                        </small>
                        <h3 className="text-base text-justify text-gray-300 my-4">Naz Solution LTD, a stalwart in the industry for 4 years, excels in IT-enabled client management. Our success is rooted in a dynamic team of amazing employees. We are on the lookout for determined and talented youngsters, eager to embark on a fulfilling career. Our focus extends beyond mere recruitment; we seek individuals poised to contribute their best for the company’s well-being. Committed to fostering youth employment, we are dedicated to providing substantial opportunities for the vibrant talent pool in US. Join us in creating a better workplace, where ambition meets opportunity. Naz Solution – your gateway to a rewarding career in a global setting.</h3>
                      <div className="flex gap-4 justify-center">
                      {/* <ul className="text-left py-8 text-gray-100">
                            <li><span className="text-indigo-600">{checkFont}</span> Best Prices</li>
                            <li><span className="text-indigo-600">{checkFont} </span>
                            Test Drive Services</li>
                            <li><span className="text-indigo-600">{checkFont} </span> 
                            Car Drop Facility</li>
                            <li><span className="text-indigo-600">{checkFont} </span> 
                            Special Finance Facility</li>
                        </ul> */}
                        {/* <ul className="text-left py-8 text-gray-100">
                        <li><span className="text-indigo-600">{checkFont} </span> 
                            National Coverage</li>
                            <li><span className="text-indigo-600">{checkFont} </span> 
                            No Booking Fee</li>
                            <li><span className="text-indigo-600">{checkFont} </span> 
                            Frequent Inspections</li>
                            <li><span className="text-indigo-600">{checkFont} </span> 
                            Well Maintained Vehicles</li>
                        </ul> */}
                      </div>

                    </div>
            </div>
        </div>
        
        </div>
        </div>
    );
};

export default About;