import React from 'react';
import "./Footer.css"
import { Link } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
// import Lottie from "react-lottie"
// import logo from '../../../images/logo/f.svg'
import logo1 from '../../images/naz-niaz.png'
import logo2 from '../../images/nazXsolution.png'
import logo3 from '../../images/naz-tech.png'
import logo4 from '../../images/naz-dot-cut.png'
import logo5 from '../../images/naz design.png'
import logo6 from '../../images/naz double layer 2.png'
import logo7 from '../../images/naz simple.png'
import logo8 from '../../images/naz with bg-black.png'

const Footer = () => {
    const phoneFont=<FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>
    const inboxFont=<FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
    const mapFont=<FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
    const clockFont=<FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
    const facebookFont=<FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon>
    const twitterFont=<FontAwesomeIcon icon={faTwitterSquare}></FontAwesomeIcon>
    const instaFont=<FontAwesomeIcon icon={faInstagramSquare}></FontAwesomeIcon>
    const linkedinFont=<FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
    return (
        <div className=" bg-gradient-to-r from-blue-950 from-10% via-gray-950 via-50% to-blue-900 to-90% ">
           <div className="text-white p-4">
           <div className="p-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 sm:grid-cols-1 text-left gap-4">
                <div className="">
                    <h3 className="text-slate-300 bg-opacity-95 block text-4xl mb-2">
                    <img src={logo6} alt="" className=' lg:w-3/4' />
                       
                         </h3>
                    <p className="text-sky-600 text-sm">
                    Established in 2021, Naz Solution is  a back office supporter.                      
                    </p>
<p className="text-4xl my-3 text-yellow-400">Follow Us</p>
                    <p className="flex gap-3">
                        <a href="https://www.facebook.com/nazsolutionbd?mibextid=ZbWKwL" className="text-4xl text-slate-200 hover:text-gray-100"><span>{facebookFont}</span></a>
                       <a href="https://x.com/NazSolution" className="text-4xl text-slate-200 hover:text-gray-100"> <span>{twitterFont}</span></a>
                        <a href="https://www.instagram.com/nazsolutionllc/" className=" text-4xl text-slate-200 hover:text-gray-100"><span>{instaFont}</span></a>
                        <a href="https://www.linkedin.com/in/naz-solution-llc-b1ab22337" className=" text-4xl text-slate-200 hover:text-gray-100"><span>{linkedinFont}</span></a>
                    </p>
                    </div>
                <div className="">
                   <h5  className="text-sky-600  text-2xl mb-4"> Quick Link</h5>
                   <ul className="space-y-2">
                   <li><Link className="hover:border-sky-600 border-b-2" to="/">
                           Home
                       </Link></li>
                       
                       <li><Link className="hover:border-sky-600 border-b-2"  to="/services">
                       Service Pricing 
                       </Link></li>
                       <li><Link className="hover:border-sky-600 border-b-2"  to="/about-us">
                      About
                       </Link></li>
                       <li> <Link className="hover:border-sky-600 border-b-2" to="/contact-us">
                       Contact Us
                       </Link></li>
                   </ul>
                    </div>
                <div className="">
                   <h5 className="text-sky-600 text-2xl mb-4">Policy</h5>
                   <ul className="space-y-2">
                   <li> <Link className="hover:border-sky-600 border-b-2" to="/term-conditions">
                       Term & Conditions 
                       </Link></li>
                       <li> <Link className="hover:border-sky-600 border-b-2" to="/privacy-policy">
                       Privacy Policy
                       </Link></li>
                       <li> <Link className="hover:border-sky-600 border-b-2" to="/refund-policy">
                       Refund Policy 
                       </Link></li>
                     
                   </ul>
                  
                    </div>
                <div className="">
                    <h5 className="text-sky-600 text-2xl mb-4">Information</h5>
                    <ul className="space-y-2">
                    <li><span className="mr-2 text-yellow-400">{phoneFont}</span> <small className="text-sm"> +1 3074436379</small></li>
                    <li><span className="mr-2 text-yellow-400">{inboxFont}</span> <small className="text-sm">support@nazsolution.com</small></li>
                    <li><span className="mr-2 text-yellow-400 ">{mapFont}</span> <small className="text-sm">30 N Gould St Suite R, Sheridan, WY 82801, USA</small></li>
                    <li><span className="mr-2 text-yellow-400 ">{clockFont}</span> <small className="text-sm">24/7 Customer Support</small></li>
                    </ul>
                    </div>


            </div>
            <div className=" border-t-2 border-slate-200 text-center pt-3">
<small className='text-sky-500'>&copy; Copyright 2024 | <Link className="" to="/">
NazSolution.com
                       </Link></small>
            </div>
           </div>
        </div>
    );
};

export default Footer;