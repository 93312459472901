import React from 'react';
import './Refund.css'
const Refund = () => {
    return (
        <div>
            <p className="text-sky-600 md:mt-20 mt-10 text-5xl">| Refund Policy </p>

<div class="w-full h-full px-4 md:px-8 lg:px-12 py-12 md:py-16 lg:py-24 ">
  <div class="mx-auto shadow-lg rounded-lg border border-slate-800 m-4 overflow-hidden">

    {/* Tab 1  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab1" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab1" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Refund Eligibility</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️ Clients may request a refund within 30 days of the initial payment date.
       
        </li>

        <li class="text-lg ">⚫️ Refund requests must be submitted in writing via email or through our contact.</li>
       </ol>
      </div>
    </div>


     {/* Tab 2  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab2" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab2" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Conditions for Refund
      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️ If the client feels that the services provided have not met the agreed-upon specifications as outlined in the service agreement.

       
        </li>

        <li class="text-lg">⚫️ If the client has not received any work or deliverables during the service package days.
        </li>
        <li class="text-lg">⚫️ If there was a failure on our part to communicate effectively regarding project updates or milestones.


        </li>
       </ol>
      </div>
    </div>

{/* tab 3  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab3" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab3" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Refund Process</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️ Upon receiving a refund request, Naz Solution will review the request within 3 business days.

       
        </li>

        <li class="text-lg ">⚫️ We may reach out to the client for additional information or clarification regarding the refund request.
        </li>

        <li class="text-lg ">⚫️ If approved, the refund will be processed back to the original payment method used within 7 business days.


        </li>
       </ol>
      </div>
    </div>

    {/* Tab 4  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab4" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab4" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600"> Non-Refundable Services
      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
        <h3 className='text-xl font-bold'>Certain services may not be eligible for a refund, including but not limited to:</h3>
       <ol>
        <li class="text-lg  ">⚫️ Services that have already been delivered and accepted by the client.

       
        </li>

        <li class="text-lg ">⚫️ If the client takes services from different company on the same service which we delivered to them.</li>
       </ol>
      </div>
    </div>
  </div>
</div>

        </div>
    );
};

export default Refund;