'use client'
import React from 'react';
import customer from "../../images/customer-relationship.avif"
import video1 from "../../images/video-editor.avif"
import video2 from "../../images/video editor-2.jpeg"
import social1 from "../../images/social media-2.jpg"
import social2 from "../../images/social media.jpeg"
import paypal from "../../images/Paypal.png"
import stripe from "../../images/stripe.png"
import { Link } from 'react-router';
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'


const ServicePricing = () => {

    const [open, setOpen] = useState(false)

    return (
        <div>
            <div className='w-full h-full text-left p-8 py-8'>
            <p className="text-gray-950 text-3xl text-center py-4 lg:text-5xl">Discover 
            <small className='text-3xl lg:text-5xl text-sky-600 '> The Perfect Plan</small> </p>

                <div className=' mt-14   grid md:grid-cols-3 lg:grid-cols-3 gap-8'>
                <div class="max-w-lg  flex flex-col  py-4 bg-sky-950 hover:bg-sky-900  border border-gray-200 transition duration-200 hover:border-sky-600 rounded-lg shadow ">
 <div className='px-4  py-2 flex flex-col gap-4 '>
    <p className='text-left text-base text-white'>Basic Package</p>
    <p class="mb-3 font-normal text-base text-gray-400 text-left dark:text-gray-400">Quick response, simple, brand-ready, and live!
</p>

 </div>
 <div>
 <p className='px-4 text-2xl text-left text-yellow-200 font-black '>$30</p>
 </div>
    <div class="px-5 py-2">
       
           <button onClick={()=>setOpen(true)} className='border border-sky-600 p-4 w-full rounded-lg transition duration-200 hover:bg-sky-600 hover:text-white text-white font-bold'>Order Now</button>

           
        
           
        <div className='flex flex-col gap-4 py-4'>
        <p class=" text-sm text-gray-300   ">✔️ Consider the audience</p>
        <p class=" text-sm text-gray-300   ">✔️ Listen first</p>
        <p class=" text-sm text-gray-300   ">✔️ Be compelling</p>
        {/* <p class="pt-8 pb-4 text-md text-gray-700   ">✔️ Use amplification tools</p> */}
        {/* <p class=" text-sm text-gray-700   ">✔️ Be authentic</p> */}
        </div>



    </div>
    <div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-xl text-white p-2'>🕓 4 Days Delivery</p>

</div>
</div>

<div class="max-w-lg  flex flex-col  py-4 bg-sky-950 hover:bg-sky-900  border border-gray-200 transition duration-200 hover:border-sky-600 rounded-lg shadow ">
 <div className='px-4  py-2 flex flex-col gap-4 '>
    <p className='text-left text-base text-white'>Standard Package</p>
    <p class="mb-3 font-normal text-base text-gray-400 text-left dark:text-gray-400">Quick response, simple, brand-ready, and live!
</p>

 </div>
 <div>
 <p className='px-4 text-2xl text-left text-yellow-200 font-black '>$90</p>
 </div>
    <div class="px-5 py-2">
       
    <button onClick={()=>setOpen(true)} className='border border-sky-600 p-4 w-full rounded-lg transition duration-200 hover:bg-sky-600 hover:text-white text-white font-bold'>Order Now</button>
        <div className='flex flex-col gap-4 py-4'>
        <p class=" text-sm text-gray-300   ">✔️ Consider the audience</p>
        <p class=" text-sm text-gray-300   ">✔️ Listen first</p>
        <p class=" text-sm text-gray-300   ">✔️ Be compelling</p>
        {/* <p class="pt-8 pb-4 text-md text-gray-700   ">✔️ Use amplification tools</p> */}
        <p class=" text-sm text-gray-300   ">✔️ Be authentic</p>
        </div>



    </div>
    <div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-xl text-white p-2'>🕓 7 Days Delivery</p>

</div>
</div>

<div class="max-w-lg  flex flex-col  py-4 bg-sky-950 hover:bg-sky-900  border border-gray-200 transition duration-200 hover:border-sky-600 rounded-lg shadow ">
 <div className='px-4  py-2 flex flex-col gap-4 '>
    <p className='text-left text-base text-white'>Advance Package</p>
    <p class="mb-3 font-normal text-base text-gray-400 text-left dark:text-gray-400">Quick response, simple, brand-ready, and live!
</p>

 </div>
 <div>
 <p className='px-4 text-2xl text-left text-yellow-200 font-black '>$149</p>
 </div>
    <div class="px-5 py-2">
       
    <button onClick={()=>setOpen(true)} className='border border-sky-600 p-4 w-full rounded-lg transition duration-200 hover:bg-sky-600 hover:text-white text-white font-bold'>Order Now</button>
        <div className='flex flex-col gap-4 py-4'>
        <p class=" text-sm text-gray-300   ">✔️ Consider the audience</p>
        <p class=" text-sm text-gray-300   ">✔️ Listen first</p>
        <p class=" text-sm text-gray-300   ">✔️ Be compelling</p>
        <p class=" text-sm text-gray-300   ">✔️ Use amplification tools</p>
        <p class=" text-sm text-gray-300  ">✔️ Be authentic</p>
        </div>



    </div>
    <div className='flex justify-around'>
{/* <p className='text-xl text-orange-500 p-2'>Start From</p> */}
<p className='text-xl text-white p-2'>🕓 14 Days Delivery</p>

</div>
</div>

                    
                </div>
                <p className="text-pink-500 text-md text-center py-6 lg:text-lg">Have a complex or custom project? &nbsp; &nbsp;  
               <Link to="/contact-us"> <button className='text-md lg:text-lg border border-sky-600 p-3 rounded-lg text-gray-900 transition duration-200 hover:bg-sky-600 hover:text-white '> Let's discuss</button></Link> </p>
            </div>

            {/* Modal  */}

            <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:size-10">
                  <CheckCircleIcon aria-hidden="true" className="size-10 text-sky-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    Best Packages <br></br> Start From $30 to $149
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-md text-center text-gray-500">
                     You can order through
                     <div className='flex gap-2 justify-center items-center'>
                        <img src={paypal}  className="w-32 h-32" alt="" />
                        <p>or</p>
                        <img src={stripe} className="w-32 h-32" alt="" />
                     </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                // onClick={() => setOpen(false)}

                className="inline-flex w-full justify-center rounded-md border border-sky-600  px-3 py-2 text-sm font-semibold text-sky-600 shadow-sm transition duration-200 hover:bg-sky-600 hover:text-white sm:ml-3 sm:w-auto"
              >
                <Link to='/contact-us'>Proceed to Checkout</Link>
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
        </div>
    );
};

export default ServicePricing;