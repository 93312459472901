import React from 'react';
import './Refund.css'

const TermCondition = () => {
    return (
        <div>
            <p className="text-sky-600 md:mt-20 mt-10 text-5xl">| Terms & Conditions </p>

<div class="w-full h-full px-4 md:px-8 lg:px-12 py-12 md:py-16 lg:py-24 ">
  <div class="mx-auto shadow-lg rounded-lg border border-slate-800 m-4 overflow-hidden">

    {/* Tab 1  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab1" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab1" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Acceptance of Terms</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️ Please read the terms and conditions of use carefully before accessing, using or obtaining any of the tools (either online or in the form of Spreadsheets or Microsoft Office documents) from the Naz Solution website.
       
        </li>

        <li class="text-lg ">⚫️ By accessing tools from the Naz Solution website, or any other Call Centre Helper platform, you agree to be bound by these terms and conditions, as well as our privacy policy.</li>
       </ol>
       <h3 className='text-xl font-bold pt-4 text-red-500'>ANY PARTICIPATION IN THIS SERVICE WILL CONSTITUTE ACCEPTANCE OF THIS AGREEMENT.</h3>
      </div>
    </div>


     {/* Tab 2  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab2" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab2" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Intellectual Property Rights
      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️ These tools and their content are protected by copyright and other intellectual property rights and laws. In accessing the tools on our site, you are agreeing to use the content, and the results given, solely for personal, non-commercial use or use internally within your business. None of the content may be copied, downloaded, reproduced, sold, transmitted, stored or distributed without the prior written consent of the Naz Solution. This excludes the downloading, copying and/or printing off pages of the Website for personal, non-commercial use, or using internally within your business. You must not modify or place any of the tools onto another website.

       
        </li>
       </ol>
      </div>
    </div>

{/* tab 3  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab3" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab3" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Indemnity</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg ">⚫️You agree to indemnify and hold Naz Solution harmless from and against any breach you make of these Terms and Conditions and any claim or demand brought against Naz Solution by any third party arising out of your use of the Services and/or any Content submitted, posted or transmitted through our tools. This includes, without limitation, all claims, actions, proceedings, losses, liabilities, damages, costs, expenses (including reasonable legal costs and expenses) howsoever suffered or incurred by Naz Solution, in consequence of your breach of these Terms and Conditions.

        </li>

       
       </ol>
      </div>
    </div>

    {/* Tab 4  */}
    <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab4" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab4" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600"> Disclaimers and Limitation of Liability
      </label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg p-3 ">⚫️ Use of our tools is at your own risk. The tools are provided on an “AS IS” and “AS AVAILABLE” basis, without any representation or endorsement made and without warranty of any kind whether express or implied. This includes, but is not limited to, the implied warranties of satisfactory quality, fitness for a particular purpose, accuracy, security, non-infringement and compatibility.

       
        </li>

        <li class="text-lg p-3">⚫️ To the extent permitted by law, Naz Solution are not to be held liable for any direct or indirect or resulting loss or damage whatsoever (including without data, profits, opportunity limitation loss of business) that arise from a connection to the use of our tools.</li>
        <li class="text-lg p-3">⚫️  Naz Solution makes no warranty that the tools provided will meet your requirements, be uninterrupted, error-free, or that the server, which makes the tools available, are free of viruses or anything else that may be harmful or destructive (although we are careful to keep our servers virus free).</li>
        <li class="text-lg p-3">⚫️Nothing in these terms and conditions will impact upon the statutory rights that you have as a consumer.</li>
       </ol>
      </div>
    </div>

     {/* Tab 5 */}
     <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab5" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab5" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Changes to Terms and Conditions</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️ For any reason, at any time, we may modify these terms and conditions, by posting a new version on our website. But, these changes do not influence the rights and obligations, before the changes were made.
       
        </li>

        <li class="text-lg ">⚫️ Your continued use of our tools, following any modifications to the terms and conditions, will be subject to the terms and conditions in place at the time of your use. So, please review these terms and conditions periodically for any changes.</li>
       </ol>
     
      </div>
    </div>

     {/* Tab 6  */}
     <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab6" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab6" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Termination</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️ We hold the right to terminate your access to any or all of the tools at any time, without notice or any specific reason for doing so. We may also at any time, at our own discretion, discontinue the tools or any part thereof without prior notice. Therefore, you agree that we shall not be liable to you or any third party for any termination of your access to any or all of our tools.
       
        </li>

       </ol>
    
      </div>
    </div>

     {/* Tab 7 */}
     <div className='border border-slate-800 shadow-lg rounded-lg m-4'>
      <input type="radio" name="tailwind_accordion" id="tab7" class="accordion__input px-2 py-4 hidden"/>
      
      <label for="tab7" class="accordion__label p-4 block  font-bold cursor-pointer relative hover:bg-sky-600/30  text-sky-600">Governing Law</label>
      
      <div class="accordion__content border border-t-2 border-t-slate-800 text-justify  leading-relaxed p-6 hidden">
       <ol>
        <li class="text-lg  ">⚫️ These Terms and Conditions shall be governed by and construed in accordance with the law of England and Wales, and you hereby submit to the exclusive jurisdiction of the English courts.
       
        </li>

        <li class="text-lg ">⚫️ If you have any objections to any of these terms and conditions, or any subsequent variations to the terms and conditions or become dissatisfied with our tools, you must immediately terminate use of the tool in question.</li>
       </ol>
     
      </div>
    </div>
  </div>
</div>

        </div>
    );
};

export default TermCondition;